// @ts-nocheck
import React, { useEffect, useState, useContext} from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import DeleteIcon from '@mui/icons-material/Delete'
import IDish from '../../../../interfaces/dish'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

interface IProps {
	dishes: IDish[],
	removeDish: any,
	setMenu: any,
	menu: any,
	categoryDishesSelected: string
}

const MenuDishesList = (props: IProps): JSX.Element => {
	const theme = useTheme()
	
	const onDragEnd = (result) => {
		if (!result.destination) return
		const { source, destination } = result
		const copiedDishes = _.cloneDeep(props.dishes)
		const [removed] = copiedDishes.splice(source.index, 1)
		copiedDishes.splice(destination.index, 0, removed)
		props.setMenu({...props.menu, ...{[props.categoryDishesSelected]: copiedDishes}})
	}

	return (
		<DragDropContext className="text-center d-flex flex-column" onDragEnd={(result) => onDragEnd(result)} >
			<Droppable droppableId={'qew'} className="menu-list-order-column">
			{(provided, snapshot) => (
				<div
					className="text-center"
					ref={provided.innerRef}
					{...provided.droppableProps}
				>
					{props.dishes.map((dish: IDish, index: number) => {
						return (
							<Draggable key={dish._id} draggableId={dish._id} index={index} style={(_isDragging, draggableStyle) => ({ ...draggableStyle, position: 'static' })}>
							{(provided) => (
							  	<div
									className="dish-from-menu-list-order"
									ref={provided.innerRef}
									{...provided.draggableProps}
									{...provided.dragHandleProps}
								>
									<Card className="text-center" key={dish._id}>
										<Box className="d-flex flex-column text-center">
											<CardContent sx={{ flex: '1 0 auto' }}>
											<Typography component="div" variant="h5">
												{dish.name?.es ?? dish.name?.ca}
											</Typography>
											<Typography variant="subtitle1" color="text.secondary" component="div">
												{dish.description?.es ?? dish.description?.ca}
											</Typography>
											</CardContent>
										</Box>
										<DeleteIcon className="mb-2 text-right" role="button" color="error" fontSize="large" onClick={() => props.removeDish(dish)} />
										{/*Image 
											<CardMedia
												component="img"
												sx={{ width: 151 }}
												image="/static/images/cards/live-from-space.jpg"
												alt="Live from space album cover"
											/>
										*/}
									</Card>
								</div>
							)}
						  	</Draggable>
						)
					})}
					{provided.placeholder}
				</div>
			)}
			</Droppable>
		</DragDropContext>
	)
}
export default MenuDishesList