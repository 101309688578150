import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import { Snackbar, Alert, AlertColor } from '@mui/material'
import axios from 'axios'
import _ from 'underscore'
import wait from 'waait'

interface NotificationStateInterface {
    message: string,
    status: AlertColor
}

interface NotificationPropsInterface {
    propsNotification: NotificationStateInterface,
    setPropsNotification: React.Dispatch<React.SetStateAction<any>>
}

const Notification: React.FC<NotificationPropsInterface> = ({propsNotification, setPropsNotification}: NotificationPropsInterface) => {
    
    const [open, setOpen] = useState<boolean>(true)

    useEffect(() => {
        if (propsNotification.message) {
            setOpen(true)
			handleClose()
        }
    }, [propsNotification.message])

    const handleClose = async() => {
		await wait(2000)
		setPropsNotification({...propsNotification, ...{message: ''}})
		setOpen(false)
    }

    return (
        <>
            {propsNotification.message &&
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={open}
                    onClose={handleClose}
                    key={'top' + 'right'}
                >
                    <Alert severity={propsNotification.status}>{propsNotification.message}</Alert>
                </Snackbar>
            }
        </>
    )
}

export {
    Notification,
    type NotificationPropsInterface,
    type NotificationStateInterface
}
