import * as React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'

const theme = createTheme({
    components: {
        MuiTablePagination: {
            defaultProps: {
                getItemAriaLabel: (type) =>
                    `Ir hacia la pagina ${type === 'next' ? 'Siguiente' : 'Previous'}`,
            }
        }
    }
})

interface IProps {
    rows: any,
    columns: any,
	columnGroupingModel?: any[],
    rowFieldId?: string,
    classParent?: string,
    cellClassNameFunction?: string | null,
    pageSize?: number
}

const Table: React.FC<IProps> = ({
    rows,
    columns,
    columnGroupingModel = [],
    rowFieldId = '_id',
    classParent = 'table',
    cellClassNameFunction = null,
    pageSize = 10
}: IProps): JSX.Element => {
	
    const getRowSpacing = React.useCallback((params: any) => {
        return {
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5
        }
    }, [])

    const columnsStylized = columns.map((column: any) => {
        column.headerClassName = 'mui-table-header-big-bold'
        return column
    })

    return (
        <div className={classParent}>
            <ThemeProvider theme={theme}>
                <DataGrid
                    experimentalFeatures={{ columnGrouping: true }}
                    columnGroupingModel={columnGroupingModel}
                    rows={rows}
                    columns={columnsStylized}
                    initialState={{
                        pagination: { paginationModel: { pageSize: pageSize } },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    getRowId={(row) => row[rowFieldId]}
                    autoHeight={true}
                    getRowHeight={() => 'auto'}
                    sx={{
                        boxShadow: 2
                    }}
                    getRowSpacing={getRowSpacing}
                    componentsProps={{
                        pagination: {
                          labelRowsPerPage: "Filas por página"
                        }
                    }}
                    localeText={{
                        footerTotalRows: 'Filas Totales: ',
                        footerRowSelected: (count) =>
                            count !== 1
                                ? `${count.toLocaleString()} filas seleccionadas`
                                : `${count.toLocaleString()} fila seleccionada`,
                        columnHeaderSortIconLabel: 'Ordenar',
                        columnMenuLabel: 'Menu',
                        columnMenuShowColumns: 'Mostrar Columnas',
                        columnMenuManageColumns: 'Manejar Columnas',
                        columnMenuFilter: 'Filtrar',
                        columnMenuHideColumn: 'Ocultar Columna',
                        columnMenuUnsort: 'Desordenar',
                        columnMenuSortAsc: 'Ordenar por ASC',
                        columnMenuSortDesc: 'Ordenar por DESC',
                        filterOperatorContains: 'contiene',
                        filterOperatorEquals: 'igual a',
                        filterOperatorStartsWith: 'empieza con',
                        filterOperatorEndsWith: 'acaba con',
                        filterOperatorIs: 'es',
                        filterOperatorNot: 'no es',
                        filterOperatorAfter: 'es después',
                        filterOperatorOnOrAfter: 'está en o después',
                        filterOperatorBefore: 'es antes',
                        filterOperatorOnOrBefore: 'está en o antes',
                        filterOperatorIsEmpty: 'esta vacio',
                        filterOperatorIsNotEmpty: 'no esta vacio',
                        filterOperatorIsAnyOf: 'es cualquiera de',
                        toolbarColumns: 'Columnas',
                        toolbarColumnsLabel: 'Seleccionar Columnas',
                        filterPanelAddFilter: 'Añadir filtro',
                        filterPanelRemoveAll: 'Quitar todo',
                        filterPanelDeleteIconLabel: 'Borrar',
                        filterPanelLogicOperator: 'Operador Logico',
                        filterPanelOperator: 'Operador',
                        filterPanelOperatorAnd: 'Y',
                        filterPanelOperatorOr: 'O',
                        filterPanelColumns: 'Columnas',
                        filterPanelInputLabel: 'Valor',
                        filterPanelInputPlaceholder: 'Filtrar valor',
                        columnsPanelTextFieldLabel: 'Encontrar columna',
                        columnsPanelTextFieldPlaceholder: 'Titulo de Columna',
                        columnsPanelDragIconLabel: 'Reordenar Columna',
                        columnsPanelShowAllButton: 'Mostrar todas',
                        columnsPanelHideAllButton: 'Ocultar todas',
                        noRowsLabel: 'Sin resultados'
                    }}
                />
            </ThemeProvider>
        </div>
    )
}

export default Table