import React, { useEffect, useState, useContext} from 'react'
import { useParams } from "react-router-dom"
import Textarea from '@mui/joy/Textarea'
import { FormControl, FormLabel, Button, OutlinedInput, Select, MenuItem, TextField, InputLabel, Snackbar, Stack, Chip, Divider, Checkbox, FormControlLabel, Autocomplete } from '@mui/material'
import axios from 'axios'
import _ from 'lodash'
import { Notification, type NotificationStateInterface } from '../../../common-components/Notification'
import { UserContext, UserDetailsContextType } from '../../../contexts/User.context'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import DeleteIcon from '@mui/icons-material/Delete'
import dayjs, { Dayjs } from 'dayjs'
import wait from 'waait'
import { IAvailableLangs, availableLangs, dessertsCategory, dishesCategoryTranslations, drinksCategory, endpointDishes, endpointMenus, endpointRestaurants, endpointTemplates, endpointWebGenerator, errorLoadMessage, errorSaveMessage, mainsCategory, secondsCategory, startersCategory, winesCategory } from '../../../constants'
import { logError, showNotification } from '../../../utility/utils'
import MenuDishesList from './Embed/MenuDishesList'
import IDish from '../../../interfaces/dish'

interface ILangsObject {
    [property: string]: Boolean
}

interface ILangNameObject {
    [property: string]: string
}

interface  MenuPayload {
    name: ILangNameObject
    visible: boolean
    order: number,
	starters: any[],
    mains: any[],
	seconds: any[],
    desserts: any[],
    drinks: any[],
    wines: any[]
}

const menuModelDummy:  MenuPayload = {
	name: {
		'es': '',
		'en': '',
		'ca': ''
	},
	visible: true,
	order: 1,
	starters: [],
	mains: [],
	seconds: [],
	desserts: [],
	drinks: [],
	wines: [],
}

const MenuFormPage: React.FC = () => {
	const { menuId } = useParams()
    const { userDetails } = useContext(UserContext) as UserDetailsContextType

    const [menu, setMenu] = useState<any>(null)
	const [dishesFromCategorySelected, setDishesFromCategorySelected] = useState<IDish[]>([])
	const [startersDishes, setStartersDishes] = useState<IDish[]>([])
	const [mainsDishes, setMainsDishes] = useState<IDish[]>([])
	const [secondsDishes, setSecondsDishes] = useState<IDish[]>([])
	const [dessertsDishes, setDessertsDishes] = useState<IDish[]>([])
	const [drinksDishes, setDrinksDishes] = useState<IDish[]>([])
	const [winesDishes, setWinesDishes] = useState<IDish[]>([])
	const [categoryDishesSelected, setCategoryDishesSelected] = useState<string>(startersCategory)
	
    const [propsNotification, setPropsNotification] = useState<NotificationStateInterface>({
        message: '',
        status: 'success'
    })
    const [langNameSelected, setLangNameSelected] = useState<string>('es')
	
    const [langByDefault, setLangByDefault] = useState<string>('')

    useEffect(() => {

        const getDishesData = async() => {
            try {
				const resDishes = await axios.get(`${endpointDishes}/all/${userDetails.restaurantId}`)
				if (resDishes.status === 200 && !_.isEmpty(resDishes.data)) {
					setStartersDishes(resDishes.data.filter((dish: any) => dish.category === startersCategory))
					setMainsDishes(resDishes.data.filter((dish: any) => dish.category === mainsCategory))
					setSecondsDishes(resDishes.data.filter((dish: any) => dish.category === secondsCategory))
					setDessertsDishes(resDishes.data.filter((dish: any) => dish.category === dessertsCategory))
					setDrinksDishes(resDishes.data.filter((dish: any) => dish.category === drinksCategory))
					setWinesDishes(resDishes.data.filter((dish: any) => dish.category === winesCategory))
				} else {
					throw new Error('No se han podido cargar los platos. Por favor, contacte con un administrador')
				}
            } catch(err) {
				logError(err)
				showNotification(propsNotification, setPropsNotification, errorLoadMessage, 'error')
            }
        }
        if (userDetails?.token && userDetails?.restaurantId) {
            getDishesData()
        }
        
    }, [userDetails])

	useEffect(() => {
		const getMenuData = async() => {
            try {
                const resMenus = await axios.get(`${endpointMenus}/${userDetails.restaurantId}`)
				let order = 1
                if (resMenus.status === 200 && !_.isEmpty(resMenus.data)) {
					const actualMenus = resMenus.data
					const menuFound = actualMenus.find((actualMenu: any): any => actualMenu._id === menuId)
					const lastMenu = actualMenus[actualMenus.length - 1]
					if (lastMenu?.order) {
						order = lastMenu.order
					}
					
					if (menuFound) {
						const menuToEditRaw = _.cloneDeep(menuFound)
						const menuWithDishes = findDishesFromMenu(menuToEditRaw)
						setMenu({...menuModelDummy, ...menuWithDishes})
						return
					}
                }
				setMenu({...menuModelDummy, ...{order}})
            } catch(err) {
				logError(err)
				showNotification(propsNotification, setPropsNotification, errorLoadMessage, 'error')
            }
        }

		if (menu === null && (!_.isEmpty(startersDishes) || !_.isEmpty(mainsDishes) || !_.isEmpty(dessertsDishes) || !_.isEmpty(drinksDishes) || !_.isEmpty(winesDishes))) {
			getMenuData()
		}
	}, [startersDishes, mainsDishes, secondsCategory, dessertsDishes, drinksDishes, winesDishes, menu])
	
	useEffect(() => {
		if (_.isObject(menu)) {
			switch (categoryDishesSelected) {
				case mainsCategory: 
					setDishesFromCategorySelected(mainsDishes)
					break
				case secondsCategory: 
					setDishesFromCategorySelected(secondsDishes)
					break
				case dessertsCategory: 
					setDishesFromCategorySelected(dessertsDishes)
					break
				case drinksCategory: 
					setDishesFromCategorySelected(drinksDishes)
					break
				case winesCategory: 
					setDishesFromCategorySelected(winesDishes)
					break
				case startersCategory:
				default: 
					setDishesFromCategorySelected(startersDishes)
					break
			}
		}
	}, [menu, categoryDishesSelected])

	const findDishesFromMenu = (menu: any) => {
		if (menu?.starters && !_.isEmpty(menu.starters) && !_.isEmpty(startersDishes)) {
			menu.starters = menu.starters.map((menuDish: any) => startersDishes.find((dish: any) => menuDish === dish._id) ?? null)
		}
		if (menu?.mains && !_.isEmpty(menu.mains) && !_.isEmpty(mainsDishes)) {
			menu.mains = menu.mains.map((menuDish: any) => mainsDishes.find((dish: any) => menuDish === dish._id) ?? null)
		}
		if (menu?.seconds && !_.isEmpty(menu.seconds) && !_.isEmpty(secondsDishes)) {
			menu.seconds = menu.seconds.map((menuDish: any) => secondsDishes.find((dish: any) => menuDish === dish._id) ?? null)
		}
		if (menu?.desserts && !_.isEmpty(menu.desserts) && !_.isEmpty(dessertsDishes)) {
			menu.desserts = menu.desserts.map((menuDish: any) => dessertsDishes.find((dish: any) => menuDish === dish._id) ?? null)
		}
		if (menu?.drinks && !_.isEmpty(menu.drinks) && !_.isEmpty(drinksDishes)) {
			menu.drinks = menu.drinks.map((menuDish: any) => drinksDishes.find((dish: any) => menuDish === dish._id) ?? null)
		}
		if (menu?.wines && !_.isEmpty(menu.wines) && !_.isEmpty(winesDishes)) {
			menu.wines = menu.wines.map((menuDish: any) => winesDishes.find((dish: any) => menuDish === dish._id) ?? null)
		}
		return menu
	}

	const getMenuToSave = (menuRaw: any) => {
		if (menuRaw?.starters && !_.isEmpty(menuRaw.starters)) {
			menuRaw.starters = menuRaw.starters.map((menuDish: any) => menuDish._id)
		}
		if (menuRaw?.mains && !_.isEmpty(menuRaw.mains)) {
			menuRaw.mains = menuRaw.mains.map((menuDish: any) => menuDish._id)
		}
		if (menuRaw?.seconds && !_.isEmpty(menuRaw.seconds)) {
			menuRaw.seconds = menuRaw.seconds.map((menuDish: any) => menuDish._id)
		}
		if (menuRaw?.desserts && !_.isEmpty(menuRaw.desserts)) {
			menuRaw.desserts = menuRaw.desserts.map((menuDish: any) => menuDish._id)
		}
		if (menuRaw?.drinks && !_.isEmpty(menuRaw.drinks)) {
			menuRaw.drinks = menuRaw.drinks.map((menuDish: any) => menuDish._id)
		}
		if (menuRaw?.wines && !_.isEmpty(menuRaw.wines)) {
			menuRaw.wines = menuRaw.wines.map((menuDish: any) => menuDish._id)
		}
		return menuRaw
	}
	
    const save = async() => {
        try {
            const menuCopy = getMenuToSave(_.cloneDeep(menu))
			let message = 'Menu actualizado! En breves veras los cambios en la web'
			if (menuCopy?._id) {
				await axios.patch(`${endpointMenus}/bulks`, [menuCopy])
			} else {
				menuCopy.restaurant = userDetails.restaurantId
				message = 'Menu creado! En breves veras los cambios en la web'
				await axios.post(`${endpointMenus}/bulks`, [menuCopy])
			}
            await axios.post(endpointWebGenerator)
			showNotification(propsNotification, setPropsNotification, message)
			await wait(2000)
			window.location.replace("/menus")
        } catch(err) {
            logError(err)
			showNotification(propsNotification, setPropsNotification, errorSaveMessage, 'error')
        }
    }

	const addDish = (dish: IDish | null) => {
		if (_.isObject(dish)) {
			const categoryDishes = _.cloneDeep(menu[categoryDishesSelected])
			if (!categoryDishes.find((menuDish: IDish) => menuDish._id === dish._id)) {
				const dishCopy = _.cloneDeep(dish)
				categoryDishes.push(dishCopy)
				setMenu({...menu, ...{[categoryDishesSelected]: categoryDishes}})
			}
		}
	}

	const removeDish = (dish: IDish) => {
		const categoryDishes = _.cloneDeep(menu[categoryDishesSelected])
		if (categoryDishes.find((menuDish: IDish) => menuDish._id === dish?._id)) {
			const categoryDishesFiltered = categoryDishes.filter((menuDish: IDish) => menuDish._id !== dish._id)
			setMenu({...menu, ...{[categoryDishesSelected]: categoryDishesFiltered}})
		}
	}

    return (
        <div className="text-center">
            <h1 className="title">Información del Menu</h1>
            <div className="form">
                {menu &&
                    <FormControl>
                        <div className="row-2-col">
                            <div className="row mb-5">
                                <div className="col-12 m-auto p-0">
                                    <FormControl fullWidth className="col-12 col-lg-4 mb-4 me-4 d-inline-block" sx={{ width: 170 }}>
                                        <InputLabel htmlFor="lang-desc-select" id="lang-desc-select-label">Lenguaje del Nombre</InputLabel>
                                        <Select
                                            sx={{ width: 170 }}
                                            labelId="lang-desc-select-label"
                                            id="lang-desc-select"
                                            value={langNameSelected}
                                            label="Lenguaje del Nombre"
                                            onChange={(e) => setLangNameSelected(e.target.value)}
                                        >
                                            {Object.keys(availableLangs).map((keyLang) => <MenuItem key={keyLang} value={keyLang}>{availableLangs[keyLang as keyof IAvailableLangs]}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <FormControl className="col-12 col-lg-6 item d-inline-block">
                                        <TextField
                                            id="name"
                                            label="Nombre"
											sx={{ width: 250 }}
                                            value={menu.name[langNameSelected]}
                                            onChange={(e) => {
                                                const name = _.clone(menu.name)
                                                name[langNameSelected] = e.target.value
                                                setMenu({...menu, ...{name}})
                                            }}
                                        />
                                    </FormControl>
									<FormControlLabel
										className="col-2 item d-inline-block"
										control={
											<Checkbox checked={menu.visible} onChange={(e) => setMenu({...menu, ...{visible: e.target.checked}})}/>
										}
										label="Va a estar visible?"
									/>
                                </div>
								<div className="col-12 m-auto p-0">
									<Button
										type="submit"
										onClick={save}
										className="width-fit-content m-auto"
										variant="contained"
										color="primary"
									>
										Guardar
									</Button>
								</div>
                            </div>
							<hr />
							{(!_.isEmpty(startersDishes) || !_.isEmpty(mainsDishes) || !_.isEmpty(secondsDishes) || !_.isEmpty(dessertsDishes) || !_.isEmpty(drinksDishes) || !_.isEmpty(winesDishes)) &&
								<>
									<div className="row mb-4">
										<div className="col-12 m-auto mt-4 p-0 text-center">
											<FormControl fullWidth sx={{ m: 1, width: 300 }}>
												<InputLabel id="category-dish-label">Tipos de Platos</InputLabel>
												<Select
													sx={{ width: 300 }}
													labelId="category-dish-label"
													id="category-dish"
													value={categoryDishesSelected}
													label="Tipos de Platos"
													required
													onChange={(e) => setCategoryDishesSelected(e.target.value)}
												>
													<MenuItem value={startersCategory}>{ dishesCategoryTranslations[startersCategory] }</MenuItem>
													<MenuItem value={mainsCategory}>{ dishesCategoryTranslations[mainsCategory] }</MenuItem>
													<MenuItem value={secondsCategory}>{ dishesCategoryTranslations[secondsCategory] }</MenuItem>
													<MenuItem value={dessertsCategory}>{ dishesCategoryTranslations[dessertsCategory] }</MenuItem>
													<MenuItem value={drinksCategory}>{ dishesCategoryTranslations[drinksCategory] }</MenuItem>
													<MenuItem value={winesCategory}>{ dishesCategoryTranslations[winesCategory] }</MenuItem>
												</Select>
											</FormControl>
										</div>
									</div>
									<div className="row mb-5">
										<h3 className="mb-4 mt-4">{dishesCategoryTranslations[categoryDishesSelected]}</h3>
										<div className="col-12 m-auto mb-5 p-0 text-center">
											<Autocomplete
												className="d-block m-auto"
												disablePortal
												id="autocomplete-starters"
												value={null}
												options={dishesFromCategorySelected}
												sx={{ width: 300 }}
												onChange={(event: any, dish: IDish | null) => addDish(dish)}
												getOptionLabel={(option: any) => option.name.es ?? option.name.ca}
												renderInput={(params) => <TextField {...params} label={`Añadir ${dishesCategoryTranslations[categoryDishesSelected]}`} />}
												/>
										</div>
										{!_.isEmpty(menu[categoryDishesSelected]) &&
											<MenuDishesList
												dishes={menu[categoryDishesSelected]}
												removeDish={removeDish}
												setMenu={setMenu}
												menu={menu}
												categoryDishesSelected={categoryDishesSelected}
											/>
										}
									</div>
								</>
							}
                        </div>
                        <Button
                            type="submit"
                            onClick={save}
                            className="width-fit-content m-auto"
                            variant="contained"
                            color="primary"
                        >
                            Guardar
                        </Button>
                    </FormControl>
                }
            </div>
            <Notification propsNotification={propsNotification} setPropsNotification={setPropsNotification} />
        </div>
    )
}

export default MenuFormPage
