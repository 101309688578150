import React, { useEffect, useState, useContext } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import axios from 'axios'
import { Notification, NotificationStateInterface } from '../common-components/Notification'
import { UserDetailsContextType, UserContext } from '../contexts/User.context'
import { logError, showNotification } from '../utility/utils'
import { endpointRestaurants } from '../constants'

const defaultTheme = createTheme()

export default function SignIn() {
    const { userDetails, setLogIn } = useContext(UserContext) as UserDetailsContextType
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [propsNotification, setPropsNotification] = useState<NotificationStateInterface>({
        message: '',
        status: 'success'
    })

    const handleSubmit = async(e: any) => {
        e.preventDefault()
        const data = new FormData(e.target)
        try {
            const res: any = await axios.post(`${endpointRestaurants}/login`, {
                email,
                password
            })
            if (res.status === 200 && res?.data?.token) {
                setLogIn(res.data.token)
				return
            }
			throw new Error()
        } catch(err: any) {
            if (err?.response?.data?.message === 'Incorrect password') {
				showNotification(propsNotification, setPropsNotification, 'Contraseña incorrecta', 'error')
            } else {
				showNotification(propsNotification, setPropsNotification, 'Error al iniciar sesion', 'error')
            }
            logError(err)
        }
    }

    return (
        <div>
            <Notification propsNotification={propsNotification} setPropsNotification={setPropsNotification} />
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Relaya
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            id="email"
                            label="Dirección Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                            name="password"
                            label="Contraseña"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Entrar
                        </Button>
                        {/*<Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                Olvidaste la contraseña?
                                </Link>
                            </Grid>
                        </Grid>*/}
                    </Box>
                </Box>
                <Typography variant="body2" color="text.secondary" align="center">
                    {'Copyright © Relaya '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
            </Container>
        </div>
    )
}