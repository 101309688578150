import React, { useContext, useEffect, useState}from 'react'
import DateDisplay from '../common-components/DateDisplay'
import SignIn from '../components/SignIn'
import { UserContext, UserDetailsType, UserDetailsContextType } from '../contexts/User.context'
import { Link, useLocation } from 'react-router-dom'

function getWindowDimensions() {
	const { innerWidth: windowWidth, innerHeight: windowWheight } = window
	return {
	  windowWidth,
	  windowWheight
	}
}

const TransversalPage = ({ page }: any) => {
    const { userDetails } = useContext(UserContext) as UserDetailsContextType
	const { windowWidth } = getWindowDimensions()
	const [showNavIos, setShowNavIos] = useState(false)
	const [showNormalNav, setShowNormalNav] = useState(false)
	
    const Page = page
    const location = useLocation()
	
	useEffect(() => {
		if (windowWidth > 1000) {
			setShowNavIos(false)
			setShowNormalNav(true)
		} else {
			setShowNavIos(true)
			setShowNormalNav(false)
		}
	}, [windowWidth])

    return (
        <div className="app full-width-header align-content-stretch d-flex flex-wrap">
            <div className="app-sidebar">
                <div className="logo logo-sm hidden-sidebar-logo">
                    <Link to={'/'}>Relaya</Link>
                </div>
                <div className="app-menu">
                    <ul className="accordion-menu">
                        <li className="sidebar-title">Páginas</li>
                        <li className={location.pathname === '/' ? 'active-page' : ''}>
                            <a href=""><i className="material-icons-two-tone">dashboard</i>Restaurante<i className="material-icons has-sub-menu">keyboard_arrow_right</i></a>
                            <ul className="sub-menu">
                                <li>
                                    <Link to={'/'} className="nav-link">Perfil y Web</Link>
                                </li>
                                <li>
                                    <Link to={'/menus'} className="nav-link">Menus</Link>
                                </li>
                                <li>
                                    <Link to={'/dishes'} className="nav-link">Platos</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            {userDetails.token &&
                                <Link to={'/log-out'} className="nav-link">Desconectarse <i className="material-icons-two-tone">logout</i></Link>
                            }
                        </li>
                    </ul>
                </div>
            </div>
            <div className="app-container">
                <div className="search">
                    <form>
                        <input className="form-control" type="text" placeholder="Type here..." aria-label="Search" />
                    </form>
                    <a href="#" className="toggle-search"><i className="material-icons">close</i></a>
                </div>
                <div className="app-header">
					<nav id="iphone-nav" className={`navbar navbar-expand-md ${showNavIos ? '' : 'hide'}`}>
						<div className="container-fluid">
							<button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNavbar">
								<span className="navbar-toggler-icon"></span>
							</button>
							<div className="navbar-collapse collapse" id="collapseNavbar">
								<ul className="navbar-nav ms-auto">
									<li className="nav-item">
										<a href='/' className="nav-link">Perfil y Web</a>
									</li>
									<li className="nav-item">
										<a href='/menus' className="nav-link">Menus</a>
									</li>
									<li className="nav-item">
										<a href='/dishes' className="nav-link">Platos</a>
									</li>
									{userDetails.token &&
										<li className="nav-item">
											<a href='/log-out' className="nav-link">Desconectarse <i className="material-icons-two-tone">logout</i></a>
										</li>
									}
								</ul>
							</div>
						</div>
					</nav>
					<nav id="normal-nav" className={`navbar navbar-light navbar-expand-lg ${showNormalNav ? '' : 'hide'}`}>
						<div className="container-fluid">
							<div className="navbar-nav" id="navbarNav">
								<ul className="navbar-nav me-auto">
									<a href="#" id="menu-toggle-button" className="nav-link hide-sidebar-toggle-button cursor-pointer">
										<i className="material-icons">first_page</i>
									</a>
								</ul>
				
							</div>
							<div className="d-flex">
								<ul className="navbar-nav">
									<li className="nav-item hidden-on-mobile">
										<Link to={'/'} className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}>Restaurante</Link>
									</li>
									{userDetails.token &&
										<li className="nav-item hidden-on-mobile">
											<Link to={'/log-out'} className="nav-link">Desconectarse</Link>
										</li>
									}
									{/*<li className="nav-item hidden-on-mobile">
										<a className="nav-link nav-notifications-toggle" id="notificationsDropDown" href="#" data-bs-toggle="dropdown">4</a>
										<div className="dropdown-menu dropdown-menu-end notifications-dropdown" aria-labelledby="notificationsDropDown">
											<h6 className="dropdown-header">Notifications</h6>
											<div className="notifications-dropdown-list">
												<a href="#">
													<div className="notifications-dropdown-item">
														<div className="notifications-dropdown-item-image">
															<span className="notifications-badge bg-info text-white">
																<i className="material-icons-outlined">campaign</i>
															</span>
														</div>
														<div className="notifications-dropdown-item-text">
															<p className="bold-notifications-text">Donec tempus nisi sed erat vestibulum, eu suscipit ex laoreet</p>
															<small>19:00</small>
														</div>
													</div>
												</a>
												<a href="#">
													<div className="notifications-dropdown-item">
														<div className="notifications-dropdown-item-image">
															<span className="notifications-badge bg-danger text-white">
																<i className="material-icons-outlined">bolt</i>
															</span>
														</div>
														<div className="notifications-dropdown-item-text">
															<p className="bold-notifications-text">Quisque ligula dui, tincidunt nec pharetra eu, fringilla quis mauris</p>
															<small>18:00</small>
														</div>
													</div>
												</a>
												<a href="#">
													<div className="notifications-dropdown-item">
														<div className="notifications-dropdown-item-image">
															<span className="notifications-badge bg-success text-white">
																<i className="material-icons-outlined">alternate_email</i>
															</span>
														</div>
														<div className="notifications-dropdown-item-text">
															<p>Nulla id libero mattis justo euismod congue in et metus</p>
															<small>yesterday</small>
														</div>
													</div>
												</a>
												<a href="#">
													<div className="notifications-dropdown-item">
														<div className="notifications-dropdown-item-image">
															<span className="notifications-badge">
																<img src="../../assets/images/avatars/avatar.png" alt="" />
															</span>
														</div>
														<div className="notifications-dropdown-item-text">
															<p>Praesent sodales lobortis velit ac pellentesque</p>
															<small>yesterday</small>
														</div>
													</div>
												</a>
												<a href="#">
													<div className="notifications-dropdown-item">
														<div className="notifications-dropdown-item-image">
															<span className="notifications-badge">
																<img src="../../assets/images/avatars/avatar.png" alt="" />
															</span>
														</div>
														<div className="notifications-dropdown-item-text">
															<p>Praesent lacinia ante eget tristique mattis. Nam sollicitudin velit sit amet auctor porta</p>
															<small>yesterday</small>
														</div>
													</div>
												</a>
											</div>
										</div>
									</li>*/}
								</ul>
							</div>
						</div>
					</nav>
                </div>
                <div className="app-content">
                    <div className="content-wrapper">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col">
                                    <div>
                                    {userDetails.token &&
                                        <Page />
                                    }
                                    {!userDetails.token &&
                                        <SignIn />
                                    }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransversalPage