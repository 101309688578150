// @ts-nocheck
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import MenuListItemDraggable from './MenuListItemDraggable'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { Notification } from '../../common-components/Notification'
import axios from 'axios'
import wait from 'waait'
import { logError, showNotification } from '../../utility/utils'
import { endpointMenus, endpointWebGenerator, errorSaveMessage } from '../../constants'

interface IProps {
	menus: any,
	handleClose: any,
	getMenus: any
}

const MenuListOrder = (props: IProps): JSX.Element => {
	const [menus, setMenus] = useState(props.menus)
	const [propsNotification, setPropsNotification] = useState<NotificationStateInterface>({
        message: '',
        status: 'success'
    })

	const onDragEnd = (result) => {
		if (!result.destination) return
		const { source, destination } = result
		const copiedMenus = _.cloneDeep(menus)
		const [removed] = copiedMenus.splice(source.index, 1)
		copiedMenus.splice(destination.index, 0, removed)
		const menusRaw = copiedMenus.map((menu, index) => {
			menu.order = index+1
			return menu
		})
		setMenus(copiedMenus)
	}

    const save = async() => {
        try {
            const menusCopy = _.cloneDeep(menus)
			const menusPatch = menusCopy.map(menu => {
				return {
					_id: menu._id,
					order: menu.order
				}
			})
            await axios.patch(`${endpointMenus}/bulks`, menusPatch)
            await axios.post(endpointWebGenerator)
			showNotification(propsNotification, setPropsNotification, 'Menus Actualizados!')
			await props.getMenus()
			props.handleClose()
        } catch(err) {
            logError(err)
			showNotification(propsNotification, setPropsNotification, errorSaveMessage, 'error')
        }
    }

	return (
		<>
			<DragDropContext onDragEnd={(result) => onDragEnd(result)} >
				<Droppable droppableId={'qew'} className="menu-list-order-column">
				{(provided, snapshot) => (
					<div
						className="text-center menu-list-order-draggables-container"
						ref={provided.innerRef}
						{...provided.droppableProps}
					>
						<h2>Orden de Menus</h2>
						<hr className="mb-3" />
						<Stack className="mb-5 mt-4" direction="row" spacing={2}>
							<Button
								onClick={save}
								className="width-fit-content m-auto"
								variant="contained"
								color="success"
							>
								Guardar
							</Button>
							<Button
								onClick={() => props.handleClose()}
								className="width-fit-content m-auto"
								variant="contained"
								color="error"
							>
								Cerrar
							</Button>
						</Stack>
						{menus.map((item, index) => (
							<MenuListItemDraggable key={item._id} item={item} index={index} />
						))}
						{provided.placeholder}
					</div>
				)}
				</Droppable>
			</DragDropContext>
			<Notification propsNotification={propsNotification} setPropsNotification={setPropsNotification} />
		</>
	)
}

export default MenuListOrder