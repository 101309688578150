import axios from "axios"
import React, { createContext, useState } from "react"
import { endpointRestaurants } from "../constants"
import { logError } from "../utility/utils"


interface UserDetailsType {
    token: string,
	restaurantId: string
}

interface UserDetailsContextType {
    userDetails: {
        token: string,
		restaurantId: string
    },
    setLogIn: (token: string) => void,
    setLogOut: () => void
}


const UserContext = createContext<UserDetailsContextType | null>(null)

const UserProvider = ({ children }: any) => {

    const token = localStorage.getItem("token")
	const restaurantId = localStorage.getItem("restaurantId")
	
    const [userDetails, setUserDetails] = useState<UserDetailsType>({
		token: token ?? "",
		restaurantId: restaurantId ?? ""
    })

    const setLogIn = async(token: string) => {
		try {
			localStorage.setItem("token", token)
			
			const resRestaurant = await axios.get(`${endpointRestaurants}/info`)
			if (resRestaurant.status === 200) {
				const restaurant = resRestaurant.data
				localStorage.setItem("restaurantId", restaurant._id)
				setUserDetails({...userDetails, ...{token: token, restaurantId: restaurant._id}})
			}
		} catch(err) {
			logError(err)
			setLogOut()
		}
        
    }

    const setLogOut = () => {
        localStorage.setItem("token", "")
		localStorage.setItem("restaurantId", "")
        setUserDetails({...userDetails, ...{token: "", restaurantId: ""}})
    }

    return (
        <UserContext.Provider value={{ userDetails, setLogIn, setLogOut }}>
            {children}
        </UserContext.Provider>
    )
}

export { UserProvider, UserContext, UserDetailsType,UserDetailsContextType }