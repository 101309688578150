import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import NotFoundPage from './pages/LogOutPage'
import { ROUTES } from './resources/routes-constants'
import './assets/styles/main.scss'
import './assets/styles/template.scss'
import RestaurantPage from './pages/RestaurantPage'
import 'bootstrap/dist/css/bootstrap.min.css'
import TransversalPage from './pages/TransversalPage'
import 'bootstrap/dist/css/bootstrap.min.css'
//import './assets/styles/template.min.css'
import axios from 'axios'
import LogOutPage from './pages/LogOutPage'
import MenuListPage from './pages/Menu/MenuListPage'
import MenuFormPage from './pages/Menu/MenuFormPage/MenuFormPage'
import DishListPage from './pages/Dish/DishListPage'
import DishFormPage from './pages/Dish/DishFormPage'


const RootComponent: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="*" element={<TransversalPage page={RestaurantPage} />} />
                <Route path="/log-out" element={<LogOutPage />} />
                <Route path={ROUTES.HOMEPAGE_ROUTE} element={<TransversalPage page={RestaurantPage} />} />
                <Route path={ROUTES.RESTAURANT_MENUS_LIST} element={<TransversalPage page={MenuListPage} />} />
				<Route path={ROUTES.RESTAURANT_MENUS_NEW} element={<TransversalPage page={MenuFormPage} />} />
				<Route path={ROUTES.RESTAURANT_MENUS_EDIT} element={<TransversalPage page={MenuFormPage} />} />
                <Route path={ROUTES.RESTAURANT_DISHES_LIST} element={<TransversalPage page={DishListPage} />} />
				<Route path={ROUTES.RESTAURANT_DISHES_NEW} element={<TransversalPage page={DishFormPage} />} />
				<Route path={ROUTES.RESTAURANT_DISHES_EDIT} element={<TransversalPage page={DishFormPage} />} />
            </Routes>
        </Router>
    )
}

export default RootComponent
