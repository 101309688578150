import React, { useEffect, useState, useContext} from 'react'
import { useParams } from "react-router-dom"
import Textarea from '@mui/joy/Textarea'
import { MenuItem, FormControl, FormLabel, Button, OutlinedInput, Select, TextField, InputLabel, Snackbar, Stack, Chip, Divider, Checkbox, FormControlLabel, Autocomplete, InputAdornment } from '@mui/material'
import axios from 'axios'
import _ from 'lodash'
import { Notification, type NotificationStateInterface } from '../../common-components/Notification'
import { UserContext, UserDetailsContextType } from '../../contexts/User.context'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import DeleteIcon from '@mui/icons-material/Delete'
import dayjs, { Dayjs } from 'dayjs'
import wait from 'waait'
import { dessertsCategory, dishesCategoryTranslations, drinksCategory, endpointDishes, endpointRestaurants, endpointTemplates, endpointWebGenerator, errorLoadMessage, errorSaveMessage, mainsCategory, secondsCategory, startersCategory, winesCategory } from '../../constants'
import { logError, showNotification } from '../../utility/utils'
import IDish from '../../interfaces/dish'
import { IAvailableLangs, availableLangs } from '../../constants'

type CategoryDishEnum = "starters" | "mains" | "desserts" | "drinks" | "wines";

interface  DishPayload {
    name: IAvailableLangs,
    description: IAvailableLangs,
    image: string,
	price: number,
    visible: boolean,
	category: CategoryDishEnum
}

const dishModelDummy:  DishPayload = {
	name: {
		'es': '',
		'en': '',
		'ca': ''
	},
	description: {
		'es': '',
		'en': '',
		'ca': ''
	},
	image: "",
	price: 0,
	visible: true,
	category: "starters"
}

const DishFormPage: React.FC = () => {
	const { dishId } = useParams()
    const { userDetails } = useContext(UserContext) as UserDetailsContextType

    const [dish, setDish] = useState<any>(null)

    const [propsNotification, setPropsNotification] = useState<NotificationStateInterface>({
        message: '',
        status: 'success'
    })
    const [langNameSelected, setLangNameSelected] = useState<string>('es')
	const [langDescriptionSelected, setLangDescriptionSelected] = useState<string>('es')
	
    const [langByDefault, setLangByDefault] = useState<string>('')

    useEffect(() => {
        const getDishData = async() => {
			if (dishId) {
				try {
					const resDish = await axios.get(`${endpointDishes}/${dishId}`)
					if (resDish.status === 200 && _.isObject(resDish.data)) {
						setDish({...dishModelDummy, ...resDish.data})
					} else {
						throw new Error('No se ha podido cargar el plato. Por favor, contacte con un administrador')
					}
				} catch(err) {
					logError(err)
					showNotification(propsNotification, setPropsNotification, errorLoadMessage, 'error')
				}
			} else {
				setDish(_.cloneDeep(dishModelDummy))
			}
        }

		getDishData()
        
    }, [])
	
    const save = async() => {
        try {
            const dishCopy = _.cloneDeep(dish)
			dishCopy.price = parseFloat(parseFloat(dishCopy.price).toFixed(2))
			let message = 'Plato actualizado! En breves veras los cambios en la web'
			if (dishCopy?._id) {
				await axios.patch(`${endpointDishes}/bulks`, [dishCopy])
			} else {
				dishCopy.restaurant = userDetails.restaurantId
				message = 'Plato creado! En breves veras los cambios en la web'
				await axios.post(`${endpointDishes}/bulks`, [dishCopy])
			}
            await axios.post(endpointWebGenerator)
			showNotification(propsNotification, setPropsNotification, message)
			await wait(2000)
			window.location.replace("/dishes")
        } catch(err) {
            logError(err)
			showNotification(propsNotification, setPropsNotification, errorSaveMessage, 'error')
        }
    }

    return (
        <div className="text-center">
            <h1 className="title">Información del Plato</h1>
            <div className="form text-center">
                {dish &&
                    <FormControl>
						<div className="row mb-4">
							<div className="col-12 col-lg-6 p-0 mb-5">
								<FormControl fullWidth className="col-12 mb-4 d-inline-block text-center" sx={{ width: 170 }}>
									<InputLabel htmlFor="lang-name-select" id="lang-name-select-label">Lenguaje del Nombre</InputLabel>
									<Select
										sx={{ width: 170 }}
										labelId="lang-name-select-label"
										id="lang-name-select"
										value={langNameSelected}
										label="Lenguaje del Nombre"
										onChange={(e) => setLangNameSelected(e.target.value)}
									>
										{Object.keys(availableLangs).map((keyLang) => <MenuItem key={keyLang} value={keyLang}>{availableLangs[keyLang as keyof IAvailableLangs]}</MenuItem>)}
									</Select>
								</FormControl>
								<FormControl className="col-12 mb-4 d-inline-block p-3">
									<InputLabel className="p-4" htmlFor="name">Nombre</InputLabel>
									<OutlinedInput
										id="name"
										label="Descripción"
										multiline
										fullWidth
										minRows={2}
										value={dish.name[langNameSelected]}
										onChange={(e) => {
											const name = _.clone(dish.name)
											name[langNameSelected] = e.target.value
											setDish({...dish, ...{name}})
										}}
									/>
								</FormControl>
							</div>
							<div className="col-12 col-lg-6 p-0">
								<FormControl fullWidth className="col-12 mb-4 d-inline-block" sx={{ width: 170 }}>
									<InputLabel htmlFor="lang-desc-select" id="lang-desc-select-label">Lenguaje de la Descripción</InputLabel>
									<Select
										sx={{ width: 170 }}
										labelId="lang-desc-select-label"
										id="lang-desc-select"
										value={langDescriptionSelected}
										label="Lenguaje de la Descripción"
										onChange={(e) => setLangDescriptionSelected(e.target.value)}
									>
										{Object.keys(availableLangs).map((keyLang) => <MenuItem key={keyLang} value={keyLang}>{availableLangs[keyLang as keyof IAvailableLangs]}</MenuItem>)}
									</Select>
								</FormControl>
								<FormControl className="col-12 mb-4 d-inline-block p-3">
									<InputLabel className="p-4" htmlFor="description">Descripción</InputLabel>
									<OutlinedInput
										id="description"
										label="Descripción"
										multiline
										fullWidth
										minRows={2}
										value={dish.description[langDescriptionSelected]}
										onChange={(e) => {
											const description = _.clone(dish.description)
											description[langDescriptionSelected] = e.target.value
											setDish({...dish, ...{description}})
										}}
									/>
								</FormControl>
							</div>
						</div>
						<div className="row mb-4">
							<div className="col-12 col-lg-3 m-auto mt-0 p-0 mb-4">
								<FormControl fullWidth className="col-12 mb-4 d-inline-block" sx={{ width: 170 }}>
									<InputLabel htmlFor="category-select" id="category-select-label">Categoria</InputLabel>
									<Select
										sx={{ width: 170 }}
										labelId="category-select-label"
										id="category-select"
										value={dish.category}
										label="Categoria"
										onChange={(e) => setDish({...dish, ...{category: e.target.value}})}
									>
										<MenuItem value={startersCategory}>{ dishesCategoryTranslations[startersCategory] }</MenuItem>
										<MenuItem value={mainsCategory}>{ dishesCategoryTranslations[mainsCategory] }</MenuItem>
										<MenuItem value={secondsCategory}>{ dishesCategoryTranslations[secondsCategory] }</MenuItem>
										<MenuItem value={dessertsCategory}>{ dishesCategoryTranslations[dessertsCategory] }</MenuItem>
										<MenuItem value={drinksCategory}>{ dishesCategoryTranslations[drinksCategory] }</MenuItem>
										<MenuItem value={winesCategory}>{ dishesCategoryTranslations[winesCategory] }</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div className="col-12 col-lg-3 m-auto mt-0 p-0 mb-4">
								<TextField
									label="Precio"
									type="number"
									sx={{ width: 125 }}
									value={dish.price ?? 0}
									inputProps={{
										step: 1,
									}}
									InputProps={{
										endAdornment: <InputAdornment position="end">€</InputAdornment>
									}}
									onChange={(e) => setDish({...dish, ...{price: e.target.value}})}
								/>
							</div>
							<div className="col-12 col-lg-3 m-auto mt-0 p-0">
								<FormControlLabel
									className="item d-inline-block"
									control={
										<Checkbox checked={dish.visible} onChange={(e) => setDish({...dish, ...{visible: e.target.checked}})}/>
									}
									label="Va a estar visible?"
								/>
							</div>
						</div>
                        <Button
                            type="submit"
                            onClick={save}
                            className="width-fit-content m-auto"
                            variant="contained"
                            color="primary"
                        >
                            Guardar
                        </Button>
                    </FormControl>
                }
            </div>
            <Notification propsNotification={propsNotification} setPropsNotification={setPropsNotification} />
        </div>
    )
}

export default DishFormPage
