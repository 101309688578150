import React, { useEffect, useState, useContext } from 'react'
import { UserDetailsContextType, UserContext } from '../contexts/User.context'

export default function SignOut() {
    const { userDetails, setLogOut } = useContext(UserContext) as UserDetailsContextType

    useEffect(() => {
        setLogOut()
        window.location.replace("/")
    }, [])

    return (<></>)
}