import React from 'react'
import RootComponent from './RootComponent'
import { UserProvider } from './contexts/User.context'
import AxiosInterceptorComponent from './http/axios/interceptor'

const App: React.FC = () => {
    return (
        <UserProvider>
            <link href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp" rel="stylesheet" />
            <AxiosInterceptorComponent />
            <RootComponent />
        </UserProvider>
    )
}

export default App
