import { AlertColor } from "@mui/material"
import wait from "waait"

interface NotificationStateInterface {
    message: string,
    status: AlertColor
}

const showNotification = async(
	propsNotification: NotificationStateInterface,
	setPropsNotification: React.Dispatch<React.SetStateAction<any>>,
	message: string,
	status?: string
) => {
	if (status) {
		setPropsNotification({...propsNotification, ...{message, status}})
	} else {
		setPropsNotification({...propsNotification, ...{message}})
	}
	await wait(2000)
}

const logError = (err: any) => {
	const isDev = process.env.REACT_APP_IS_DEV ?? false
	if (isDev) {
		console.log(err)
	}
}

export {
	showNotification,
	logError
}