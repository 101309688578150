import axios from 'axios'
import React, { useContext, useEffect } from 'react'
import { UserContext, UserDetailsContextType } from '../../contexts/User.context'

const AxiosInterceptorComponent: React.FC = () => {
    const { userDetails, setLogOut } = useContext(UserContext) as UserDetailsContextType

    useEffect(() => {
        // Add a request interceptor
        axios.interceptors.request.use(
            config => {
                const token = localStorage.getItem("token")
                if (token) {
                    config.headers['Authorization'] = 'Bearer ' + token
                }
                // config.headers['Content-Type'] = 'application/json';
                return config
            },
            error => {
                Promise.reject(error)
            }
        )

        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.status === 401) {
                setLogOut()
            }
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error);
        });
    }, [])

    return (<></>)
}

export default AxiosInterceptorComponent