import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../resources/routes-constants'
import SignOut from '../components/SignOut'

const LogOutPage: React.FC = () => {
    return (
        <SignOut />
    )
}

export default LogOutPage
