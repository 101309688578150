const backendHost = process.env.REACT_APP_BACKEND_HOST

export interface IAvailableLangs {
    [key: string]: string
}

export const endpointRestaurants: string = `${backendHost}/restaurants`
export const endpointTemplates: string = `${backendHost}/templates`
export const endpointWebGenerator: string = `${backendHost}/web-generator`
export const endpointMenus: string = `${backendHost}/menus`
export const endpointDishes: string = `${backendHost}/dishes`
export const endpointPreviewTemplate: string = `${backendHost}/preview-template`

export const errorLoadMessage: string = 'Error al cargar datos. Por favor, contacte con un administrador'
export const errorSaveMessage: string = 'Error al guardar. Por favor, contacte con un administrador'

export const startersCategory: string = 'starters'
export const mainsCategory: string = 'mains'
export const secondsCategory: string = 'seconds'
export const dessertsCategory: string = 'desserts'
export const winesCategory: string = 'wines'
export const drinksCategory: string = 'drinks'

export const dishesCategoryTranslations = {
	[startersCategory]: 'Entrantes',
	[mainsCategory]: 'Platos Principales',
	[secondsCategory]: 'Segundos Platos',
	[dessertsCategory]: 'Postres',
	[winesCategory]: 'Vinos',
	[drinksCategory]: 'Bebidas',
}

export const availableLangs: IAvailableLangs = {
    'es': 'Español',
    'en': 'Ingles',
    'ca': 'Catalán'
}