import React, { useEffect, useState, useContext} from 'react'
import DateDisplay from '../common-components/DateDisplay'
import { useParams, Link } from "react-router-dom"
import Textarea from '@mui/joy/Textarea'
import { FormControl, FormLabel, Button, OutlinedInput, Select, MenuItem, TextField, InputLabel, Snackbar, Stack, Chip, Divider } from '@mui/material'
import axios from 'axios'
import _ from 'lodash'
import { Notification, type NotificationStateInterface } from '../common-components/Notification'
import { UserContext, UserDetailsContextType } from '../contexts/User.context'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import DeleteIcon from '@mui/icons-material/Delete'
import dayjs, { Dayjs } from 'dayjs'
import wait from 'waait'
import { IAvailableLangs, availableLangs, endpointPreviewTemplate, endpointRestaurants, endpointTemplates, endpointWebGenerator } from '../constants'
import { logError } from '../utility/utils'

interface HoursObject {
    start: Dayjs|null
    end: Dayjs|null
    annotation: string
}

interface HoursRestaurant {
    week: HoursObject,
    weekend: HoursObject
}

interface ILangsObject {
    [property: string]: Boolean
}

interface RestaurantPayload {
    name: string,
    phone: string,
    address?: string,
    landingText?: IAvailableLangs,
	description?: IAvailableLangs,
    templateId?: string,
	tripadvisorUrl?: string,
	twitterUrl?: string,
	instagramUrl?: string,
	facebookUrl?: string,
    hours?: HoursRestaurant,
    langs: ILangsObject
}

const restaurantModelDummy: RestaurantPayload = {
    name: '',
    phone: '',
    address: '',
	landingText: {},
    description: {},
    templateId: undefined,
	tripadvisorUrl: '',
	twitterUrl: '',
	instagramUrl: '',
	facebookUrl: '',
    hours: {
        week: {
            start: dayjs('2022-04-17T12:00'),
            end: dayjs('2022-04-17T16:00'),
            annotation: ''
        },
        weekend: {
            start: dayjs('2022-04-17T21:00'),
            end: dayjs('2022-04-17T23:30'),
            annotation: ''
        }
    },
    langs: {
        'es': true
    }
}

const RestaurantPage: React.FC = () => {
    const { userDetails } = useContext(UserContext) as UserDetailsContextType

    const [restaurant, setRestaurant] = useState<any>(null)
    const [templates, setTemplates] = useState<any>([])
    const [propsNotification, setPropsNotification] = useState<NotificationStateInterface>({
        message: '',
        status: 'success'
    })
    const [langLandingTextSelected, setLangLandingTextSelected] = useState<string>('es')
	const [langDescriptionSelected, setLangDescriptionSelected] = useState<string>('es')

    const [langByDefault, setLangByDefault] = useState<string>('')
    
    useEffect(() => {
        
        const getRestaurantData = async() => {
            try {
                const resRestaurant = await axios.get(`${endpointRestaurants}/info`)
                const resTemplates = await axios.get(`${endpointTemplates}`)
                if (resRestaurant.status === 200 && resTemplates.status === 200) {
                    setTemplates(resTemplates.data)
                    const restaurantRaw = resRestaurant.data
                    if (_.isEmpty(restaurantRaw.hours.week)) {
                        restaurantRaw.hours.week.start = dayjs('2022-04-17T12:00')
                        restaurantRaw.hours.week.end = dayjs('2022-04-17T16:00')
                        restaurantRaw.hours.week.annotation = ''
                    }
                    else {
                        restaurantRaw.hours.week.start = dayjs(`2022-04-17T${restaurantRaw.hours.week.start}`)
                        restaurantRaw.hours.week.end = dayjs(`2022-04-17T${restaurantRaw.hours.week.end}`)
                    }
                    if (_.isEmpty(restaurantRaw.hours.weekend)) {
                        restaurantRaw.hours.weekend.start = dayjs('2022-04-17T21:00')
                        restaurantRaw.hours.weekend.end = dayjs('2022-04-17T23:30')
                        restaurantRaw.hours.weekend.annotation = ''
                    }
                    else {
                        restaurantRaw.hours.weekend.start = dayjs(`2022-04-17T${restaurantRaw.hours.weekend.start}`)
                        restaurantRaw.hours.weekend.end = dayjs(`2022-04-17T${restaurantRaw.hours.weekend.end}`)
                    }
                    if (restaurantRaw?.langs && Object.keys(restaurantRaw.langs).length > 0) {
                        for (const keyLang of Object.keys(restaurantRaw.langs)) {
                            if (restaurantRaw.langs[keyLang]) {
                                setLangByDefault(keyLang)
                                break
                            }
                        }
                    } else {
                        restaurantRaw.langs = {'es': true}
                        setLangByDefault('es')
                    }
                    if (!restaurantRaw?.templateId) {
                        restaurantRaw.templateId = resTemplates.data[0]._id
                    }
                    if (!('description' in restaurantRaw)) {
                        restaurantRaw.description = {}
                    }
                    for (const keyLang of Object.keys(availableLangs)) {
                        if (!(keyLang in restaurantRaw.description)) {
                            restaurantRaw.description[keyLang] = ''
                        }
                    }

                    setRestaurant({...restaurantModelDummy, ...restaurantRaw})
                }
            } catch(err) {
                console.log(err)
            }
        }

        if (userDetails?.token) {
            getRestaurantData()
        }
        
    }, [userDetails])

    const save = async() => {
        try {
            const restaurantCopy = _.cloneDeep(restaurant)
			setPropsNotification({...propsNotification, ...{message: `Actualizando información...`, status: 'info'}})
			if ('webDomain' in restaurantCopy) {
				delete restaurantCopy.webDomain
			}
			if (restaurantCopy?.tripadvisorUrl && !/^https:\/\//gi.test(restaurantCopy.tripadvisorUrl)) {
				restaurantCopy.tripadvisorUrl = `https://${restaurantCopy.tripadvisorUrl}`
			}
			if (restaurantCopy?.twitterUrl && !/^https:\/\//gi.test(restaurantCopy.twitterUrl)) {
				restaurantCopy.twitterUrl = `https://${restaurantCopy.twitterUrl}`
			}
			if (restaurantCopy?.instagramUrl && !/^https:\/\//gi.test(restaurantCopy.instagramUrl)) {
				restaurantCopy.instagramUrl = `https://${restaurantCopy.instagramUrl}`
			}
			if (restaurantCopy?.facebookUrl && !/^https:\/\//gi.test(restaurantCopy.facebookUrl)) {
				restaurantCopy.facebookUrl = `https://${restaurantCopy.facebookUrl}`
			}
            if (restaurantCopy?.hours?.week?.start)  restaurantCopy.hours.week.start = restaurantCopy.hours.week.start.format('HH:mm')
            if (restaurantCopy?.hours?.week?.end)  restaurantCopy.hours.week.end = restaurantCopy.hours.week.end.format('HH:mm')
            if (restaurantCopy?.hours?.weekend?.start)  restaurantCopy.hours.weekend.start = restaurantCopy.hours.weekend.start.format('HH:mm')
            if (restaurantCopy?.hours?.weekend?.end)  restaurantCopy.hours.weekend.end = restaurantCopy.hours.weekend.end.format('HH:mm')
            await axios.patch(`${endpointRestaurants}/${restaurantCopy._id}`, restaurantCopy)
            await axios.post(endpointWebGenerator)
            setPropsNotification({...propsNotification, ...{message: `Información Actualizada! En menos de 3 minutos deberias ver los cambios!`}})
			await wait(2000)
        } catch(err) {
            logError(err)
        }
    }

    const insertLang = (e: any) => {
        const lang = e.target.value
        if (lang) {
            const langsWeb = restaurant?.langs ? _.clone(restaurant.langs) : {}
            if (!(lang in langsWeb)) {
                if (Object.keys(langsWeb).length > 1) {
                    langsWeb[lang] = false
                } else {
                    langsWeb[lang] = true
                }
                setRestaurant({...restaurant, ...{langs: langsWeb}})
            }
        }
    }

    const changeDefaultLang = (e: any) => {
        const lang = e.target.value
        if (lang) {
            const langsWeb = restaurant?.langs ? _.clone(restaurant.langs) : {}
            for (const keyLang of Object.keys(langsWeb)) {
                if (keyLang === lang) {
                    langsWeb[keyLang] = true
                } else {
                    langsWeb[keyLang] = false
                }
            }
            setLangByDefault(lang)
            setRestaurant({...restaurant, ...{langs: langsWeb}})
        }
    }

    const deleteLang = (keyLang: string) => {
        if (keyLang) {
            const langsWeb = restaurant?.langs ? _.clone(restaurant.langs) : {}
            if (keyLang in langsWeb && Object.keys(langsWeb).length > 1) {
                delete langsWeb[keyLang]
                setRestaurant({...restaurant, ...{langs: langsWeb}})
            }
        }
    }
	
    return (
        <div className="text-center">
            <h1 className="title mb-4">Información del Restaurante</h1>
            <div className="form">
                {restaurant &&
                    <FormControl>
						{'webDomain' in restaurant && restaurant?.webDomain &&
							<div className="row m-auto">
								<Button className="mt-4 width-fit-content" variant="contained">
									<Link target="_blank" className="link-normal" to={restaurant.webDomain}>
										Visitar Web
									</Link>
								</Button>
							</div>
						}
						<hr className="mt-5 mb-5"/>
                        <div className="row-2-col mb-5">
                            <div className="row mb-5">
                                <div className="col-12 col-lg-6 m-auto p-0 mt-0 mb-5">
                                    <TextField
                                        type="text"
                                        color="primary"
                                        label="Nombre"
                                        sx={{ width: 300 }}
                                        fullWidth
                                        value={restaurant.name}
										multiline
                                        onChange={(e) => setRestaurant({...restaurant, ...{name: e.target.value}})}
                                    />
                                </div>
								<div className="col-12 col-lg-6 col-md-6 p-0 mt-0 text-center m-auto">
									<FormControl fullWidth sx={{ width: 300 }}>
										<InputLabel id="template-select-label">Plantilla Web</InputLabel>
										<Select
											sx={{ width: 300 }}
											labelId="template-select-label"
											id="template-select"
											value={restaurant.templateId}
											label="Plantilla Web"
											required
											onChange={(e) => setRestaurant({...restaurant, ...{templateId: e.target.value}})}
										>
											{templates.map((template: any) => <MenuItem key={template._id} value={template._id}>{template.name}</MenuItem>)}
										</Select>
									</FormControl>
									<Button className="mt-4" variant="contained">
										<Link target="_blank" className="link-normal" to={`${endpointPreviewTemplate}/${templates.find((item: any) => item._id === restaurant.templateId).slug}/home`}>
											Previsualizar Plantilla
										</Link>
									</Button>
								</div>
                            </div>
							<div className="row mb-5">
								<div className="col-12 col-lg-6 m-auto p-0 mb-4 mt-0">
									<FormControl fullWidth className="col-12 m-auto mb-4 d-block" sx={{ width: 150 }}>
										<InputLabel id="lang-desc-select-label">Lenguaje Texto Portada</InputLabel>
										<Select
											sx={{ width: 150 }}
											labelId="lang-desc-select-label"
											id="lang-desc-select"
											value={langLandingTextSelected}
											label="Lenguaje Texto Portada"
											onChange={(e) => setLangLandingTextSelected(e.target.value)}
										>
											{Object.keys(availableLangs).map((keyLang) => <MenuItem key={keyLang} value={keyLang}>{availableLangs[keyLang as keyof IAvailableLangs]}</MenuItem>)}
										</Select>
									</FormControl>
									<FormControl className="col-12 item d-block">
										<InputLabel htmlFor="landingText">Texto Portada</InputLabel>
										<OutlinedInput
											id="landingText"
											label="Texto Portada"
											multiline
											fullWidth
											minRows={2}
											value={restaurant.landingText[langLandingTextSelected]}
											onChange={(e) => {
												const landingText = _.clone(restaurant.landingText)
												landingText[langLandingTextSelected] = e.target.value
												setRestaurant({...restaurant, ...{landingText}})
											}}
										/>
									</FormControl>
								</div>
								<div className="col-12 col-lg-6 m-auto p-0 mb-4 mt-0">
									<FormControl fullWidth className="col-12 m-auto mb-4 d-block" sx={{ width: 150 }}>
										<InputLabel id="lang-desc-select-label">Lenguaje Descripción</InputLabel>
										<Select
											sx={{ width: 150 }}
											labelId="lang-desc-select-label"
											id="lang-desc-select"
											value={langDescriptionSelected}
											label="Lenguaje Descripción"
											onChange={(e) => setLangDescriptionSelected(e.target.value)}
										>
											{Object.keys(availableLangs).map((keyLang) => <MenuItem key={keyLang} value={keyLang}>{availableLangs[keyLang as keyof IAvailableLangs]}</MenuItem>)}
										</Select>
									</FormControl>
									<FormControl className="col-12 item d-block">
										<InputLabel htmlFor="description">Descripción</InputLabel>
										<OutlinedInput
											id="description"
											label="Descripción"
											multiline
											fullWidth
											minRows={2}
											value={restaurant.description[langDescriptionSelected]}
											onChange={(e) => {
												const description = _.clone(restaurant.description)
												description[langDescriptionSelected] = e.target.value
												setRestaurant({...restaurant, ...{description}})
											}}
										/>
									</FormControl>
								</div>
							</div>
                        </div>
                        <div className="row-2-col">
                            <div className="row mb-5">
                                <div className="text-left-important item col-12 col-lg-6 m-auto p-0">
                                    <TextField
                                        type="text"
                                        color="primary"
                                        label="Telefono"
                                        fullWidth
                                        value={restaurant.phone}
										multiline
                                        onChange={(e) => setRestaurant({...restaurant, ...{phone: e.target.value}})}
                                    />
                                </div>
                                <div className="text-right-important item col-12 col-lg-6 m-auto p-0">
                                    <TextField
                                        type="text"
                                        color="primary"
                                        label="Dirección"
                                        fullWidth
                                        value={restaurant.address}
										multiline
                                        onChange={(e) => setRestaurant({...restaurant, ...{address: e.target.value}})}
                                    />
                                </div>
                            </div>
							<h3 className="mb-4">Redes Sociales</h3>
							<div className="row-2-col">
								<div className="row mb-5">
									<div className="text-left-important item col-12 col-lg-6 m-auto p-0">
										<TextField
											type="text"
											color="primary"
											label="Url de Instagram"
											fullWidth
											value={restaurant.instagramUrl}
											multiline
											onChange={(e) => setRestaurant({...restaurant, ...{instagramUrl: e.target.value}})}
										/>
									</div>
									<div className="text-right-important item col-12 col-lg-6 m-auto p-0">
										<TextField
											type="text"
											color="primary"
											label="Url de Facebook"
											fullWidth
											value={restaurant.facebookUrl}
											multiline
											onChange={(e) => setRestaurant({...restaurant, ...{facebookUrl: e.target.value}})}
										/>
									</div>
								</div>
							</div>
							<div className="row-2-col">
								<div className="row mb-5">
									<div className="text-left-important item col-12 col-lg-6 m-auto p-0">
										<TextField
											type="text"
											color="primary"
											label="Url de Tripadvisor"
											fullWidth
											value={restaurant.tripadvisorUrl}
											multiline
											onChange={(e) => setRestaurant({...restaurant, ...{tripadvisorUrl: e.target.value}})}
										/>
									</div>
									<div className="text-right-important item col-12 col-lg-6 m-auto p-0">
										<TextField
											type="text"
											color="primary"
											label="Url de twitter"
											fullWidth
											value={restaurant.twitterUrl}
											multiline
											onChange={(e) => setRestaurant({...restaurant, ...{twitterUrl: e.target.value}})}
										/>
									</div>
								</div>
							</div>
                        </div>
                        <hr className="mt-5 mb-5" />
                        <div className="row-2-col mb-5">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div className="row mb-5">
                                    <h4>Horario de Semana</h4>
                                    <div className="mt-4 text-left-important item col-6 m-auto p-0">
                                        <p className="text-center">Inicio</p>
                                        <TimePicker
                                            ampm={false}
                                            views={['hours', 'minutes']}
                                            className="item p-0"
                                            value={restaurant.hours.week.start}
                                            onChange={(value) => {
                                                const hours = restaurant.hours
                                                hours.week.start = value
                                                setRestaurant({...restaurant, ...hours})
                                            }}

                                        />
                                    </div>
                                    <div className="text-right-important item col-6 m-auto p-0">
                                        <p className="text-center">Fin</p>
                                        <TimePicker
                                            ampm={false}
                                            views={['hours', 'minutes']}
                                            className="item p-0"
                                            value={restaurant.hours.week.end}
                                            onChange={(value) => {
                                                const hours = restaurant.hours
                                                hours.week.end = value
                                                setRestaurant({...restaurant, ...hours})
                                            }}

                                        />
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <h4>Horario de Fin de Semana</h4>
                                    <div className="mt-4 text-left-important item col-6 m-auto p-0">
                                        <p className="text-center">Inicio</p>
                                        <TimePicker
                                            ampm={false}
                                            views={['hours', 'minutes']}
                                            className="item p-0"
                                            value={restaurant.hours.weekend.start}
                                            onChange={(value) => {
                                                const hours = restaurant.hours
                                                hours.weekend.start = value
                                                setRestaurant({...restaurant, ...hours})
                                            }}

                                        />
                                    </div>
                                    <div className="text-right-important item col-6 m-auto p-0">
                                        <p className="text-center">Fin</p>
                                        <TimePicker
                                            ampm={false}
                                            views={['hours', 'minutes']}
                                            className="item p-0"
                                            value={restaurant.hours.weekend.end}
                                            onChange={(value) => {
                                                const hours = restaurant.hours
                                                hours.weekend.end = value
                                                setRestaurant({...restaurant, ...hours})
                                            }}

                                        />
                                    </div>
                                </div>
                            </LocalizationProvider>
                            <hr className="mt-5 mb-5" />
                            <div className="row-2-col">
                                <h2 className="mb-5">Lenguajes de la Web</h2>
                                <div className="col-12 text-center m-auto">
                                    <FormControl fullWidth sx={{ m: 1, width: 300 }}>
                                        <InputLabel id="lang-select-label">Añadir Lenguage a la Web</InputLabel>
                                        <Select
                                            sx={{ width: 300 }}
                                            labelId="lang-select-label"
                                            id="lang-select"
                                            defaultValue={""}
                                            label="Añadir Lenguage a la Web"
                                            onChange={insertLang}
                                        >
                                            {Object.keys(availableLangs).map((keyLang) => <MenuItem key={keyLang} value={keyLang}>{availableLangs[keyLang as keyof IAvailableLangs]}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </div>
                                {restaurant?.langs && Object.keys(restaurant.langs).length > 0 &&
                                    <>
                                        <div className="col-12 text-center m-auto mt-5">
                                            <h3 className="mb-4">Lenguages Actuales</h3>
                                            <Stack
                                                direction="row"
                                                className="mt-4"
                                                justifyContent="center"
                                                divider={<Divider orientation="vertical" flexItem />}
                                                spacing={2}
                                            >
                                                {Object.keys(restaurant?.langs).map((keyLang) => {
                                                    return (
                                                        <Chip
                                                            key={keyLang}
                                                            label={availableLangs[keyLang as keyof IAvailableLangs]}
                                                            onDelete={() => deleteLang(keyLang)}
                                                            deleteIcon={<DeleteIcon />}
                                                            variant="outlined"
                                                        />
                                                    )
                                                })}
                                            </Stack>
                                        </div>
                                        <div className="col-12 text-center m-auto mt-5">
                                            <h3 className="mb-4">Lenguage Principal</h3>
                                            <FormControl fullWidth sx={{ m: 1, width: 300 }}>
                                                <InputLabel id="default-lang-select-label">Selecciona el lenguaje principal</InputLabel>
                                                <Select
                                                    sx={{ width: 300 }}
                                                    labelId="default-lang-select-label"
                                                    id="default-lang-select"
                                                    value={langByDefault}
                                                    label="Selecciona el lenguaje principal"
                                                    onChange={changeDefaultLang}
                                                >
                                                    {Object.keys(restaurant.langs).map((keyLang) => <MenuItem key={keyLang} value={keyLang}>{availableLangs[keyLang as keyof IAvailableLangs]}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <Button
                            type="submit"
                            onClick={save}
                            className="width-fit-content m-auto"
                            variant="contained"
                            color="primary"
                        >
                            Guardar
                        </Button>
                    </FormControl>
                }
            </div>
            <Notification propsNotification={propsNotification} setPropsNotification={setPropsNotification} />
        </div>
    )
}

export default RestaurantPage
