import React, { useEffect, useState, useContext} from 'react'
import { Link, useParams } from "react-router-dom"
import axios from 'axios'
import _ from 'lodash'
import { Notification, type NotificationStateInterface } from '../../common-components/Notification'
import { UserContext, UserDetailsContextType } from '../../contexts/User.context'
import DeleteIcon from '@mui/icons-material/Delete'
import dayjs, { Dayjs } from 'dayjs'
import Table from '../../common-components/Table'
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import { Button, Box, Typography } from '@mui/material'
import MenuListOrder from '../../components/Menu/MenuListOrder'
import { endpointMenus, endpointRestaurants, errorLoadMessage } from '../../constants'
import { logError, showNotification } from '../../utility/utils'

const styleModal = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const columns: GridColDef[] = [
    { field: 'name', headerName: 'Nombre', width: 150, valueGetter: (params: GridValueGetterParams) => {
        return params.row.name.es
    }},
    { field: 'visible', headerName: 'Visible', width: 100, valueGetter: (params: GridValueGetterParams) =>params.row?.visible ? 'Si' : 'No' },
    { field: 'order', headerName: 'Orden', width: 100, valueGetter: (params: GridValueGetterParams) => params.row?.order ?? '' },
	{ field: 'acciones', headerName: 'Acciones', width: 200, renderCell: (params: GridRenderCellParams) => {
		return (
			<Button
				onClick={(e) => e.preventDefault()}
				className="width-fit-content m-auto mt-4 mb-4"
				variant="contained"
				color="primary"
			>
				<Link to={`/menus/edit/${params.row._id}`} className="nav-link">Editar Menu</Link>
			</Button>
		)
	}},
  ]

const MenuListPage = (): JSX.Element => {
    const { userDetails } = useContext(UserContext) as UserDetailsContextType
    const [open, setOpen] = useState<boolean>(false)
    const [menus, setMenus] = useState<any>([])
    const [propsNotification, setPropsNotification] = useState<NotificationStateInterface>({
        message: '',
        status: 'success'
    })

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

	const getMenus = async() => {
		try {
			const resMenus = await axios.get(`${endpointMenus}/${userDetails.restaurantId}`)
			if (resMenus.status === 200 && _.isArray(resMenus.data) && !_.isEmpty(resMenus.data)) {
				const menusRaws = resMenus.data
				setMenus(menusRaws)
			}
		} catch(err) {
			logError(err)
			showNotification(propsNotification, setPropsNotification, errorLoadMessage, 'error')
		}
	}

    useEffect(() => {
		if (userDetails?.token && userDetails?.restaurantId) {
			getMenus()
        }
    }, [])

    return (
        <div className="text-center">
            <h1 className="title">Menus</h1>
			<Stack className="text-left mb-5 mt-4" direction="row" spacing={2}>
				<Button
					className="text-left"
					variant="contained"
					color="primary"
				>
					<Link to={'/menus/new'} className="nav-link">Crear Menu</Link>
				</Button>
				{!_.isEmpty(menus) &&
					<Button
						onClick={handleOpen}
						className="text-left"
						variant="contained"
						color="primary"
					>
						Ordenar Menus
					</Button>
				}
			</Stack>
			{!_.isEmpty(menus) &&
				<Table columns={columns} rows={menus} />
			}
			<div>
				<Modal
					open={open}
					onClose={handleClose}
				>
					<Box sx={styleModal}>
						<MenuListOrder menus={menus} handleClose={handleClose} getMenus={getMenus} />
					</Box>
				</Modal>
			</div>
            <Notification propsNotification={propsNotification} setPropsNotification={setPropsNotification} />
        </div>
    )
}

export default MenuListPage
