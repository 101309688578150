import React, { useEffect, useState, useContext} from 'react'
import { Link, useParams } from "react-router-dom"
import axios from 'axios'
import _ from 'lodash'
import { Notification, type NotificationStateInterface } from '../../common-components/Notification'
import { UserContext, UserDetailsContextType } from '../../contexts/User.context'
import DeleteIcon from '@mui/icons-material/Delete'
import Table from '../../common-components/Table'
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid'
import Stack from '@mui/material/Stack'
import { Button, Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { dessertsCategory, dishesCategoryTranslations, drinksCategory, endpointDishes, errorLoadMessage, mainsCategory, secondsCategory, startersCategory, winesCategory } from '../../constants'
import { logError, showNotification } from '../../utility/utils'

const columns: GridColDef[] = [
    { field: 'name', headerName: 'Nombre', width: 250, valueGetter: (params: GridValueGetterParams) => {
        return params.row.name.es
    }},
	{ field: 'description', headerName: 'Descripción', width: 250, valueGetter: (params: GridValueGetterParams) => {
        return params.row.description.es
    }},
    { field: 'visible', headerName: 'Visible', width: 100, valueGetter: (params: GridValueGetterParams) =>params.row?.visible ? 'Si' : 'No' },
    { field: 'precio', headerName: 'Precio', width: 100, valueGetter: (params: GridValueGetterParams) => params.row?.price ? `${params.row?.price.toString()}€` : '' },
	{ field: 'acciones', headerName: 'Acciones', width: 200, renderCell: (params: GridRenderCellParams) => {
		return (
			<Button
				onClick={(e) => e.preventDefault()}
				className="width-fit-content m-auto mt-4 mb-4"
				variant="contained"
				color="primary"
			>
				<Link to={`/dishes/edit/${params.row._id}`} className="nav-link">Editar Plato</Link>
			</Button>
		)
	}}
]

interface IDishesByCategory {
	[key: string]: any[]
}

const DishListPage = (): JSX.Element => {
    const { userDetails } = useContext(UserContext) as UserDetailsContextType
    const [open, setOpen] = useState<boolean>(false)
    const [dishes, setDishes] = useState<any>([])
	const [dishesByCategory, setDishesByCategory] = useState<IDishesByCategory>({
		[startersCategory]: [],
		[mainsCategory]: [],
		[secondsCategory]: [],
		[dessertsCategory]: [],
		[drinksCategory]: [],
		[winesCategory]: [],
	})
	const [categoryDishesSelected, setCategoryDishesSelected] = useState<string>(startersCategory)
    const [propsNotification, setPropsNotification] = useState<NotificationStateInterface>({
        message: '',
        status: 'success'
    })

	const getDishes = async() => {
		try {
			const resDishes = await axios.get(`${endpointDishes}/all/${userDetails.restaurantId}`)
			if (resDishes.status === 200 && _.isArray(resDishes.data) && !_.isEmpty(resDishes.data)) {
				const dishesRaws = resDishes.data
				setDishes(dishesRaws)
				const startersDishes = dishesRaws.filter(item => item.category === startersCategory)
				const mainsDishes = dishesRaws.filter(item => item.category === mainsCategory)
				const secondsDishes = dishesRaws.filter(item => item.category === secondsCategory)
				const dessertsDishes = dishesRaws.filter(item => item.category === dessertsCategory)
				const drinksDishes = dishesRaws.filter(item => item.category === drinksCategory)
				const winesDishes = dishesRaws.filter(item => item.category === winesCategory)
				setDishesByCategory({
					[startersCategory]: startersDishes,
					[mainsCategory]: mainsDishes,
					[secondsCategory]: secondsDishes,
					[dessertsCategory]: dessertsDishes,
					[drinksCategory]: drinksDishes,
					[winesCategory]: winesDishes
				})
			}
		} catch(err) {
			logError(err)
			showNotification(propsNotification, setPropsNotification, errorLoadMessage, 'error')
		}
	}

    useEffect(() => {
		if (userDetails?.token && userDetails?.restaurantId) {
			getDishes()
        }
    }, [])

    return (
        <div className="text-center">
            <h1 className="title mb-4">Platos</h1>
			<FormControl className="col-12 m-auto mb-4" fullWidth sx={{ m: 1, width: 300 }}>
				<InputLabel id="category-dish-label">Tipos de Platos</InputLabel>
				<Select
					sx={{ width: 300 }}
					labelId="category-dish-label"
					id="category-dish"
					value={categoryDishesSelected}
					label="Tipos de Platos"
					required
					onChange={(e) => setCategoryDishesSelected(e.target.value)}
				>
					<MenuItem value={startersCategory}>{ dishesCategoryTranslations[startersCategory] }</MenuItem>
					<MenuItem value={mainsCategory}>{ dishesCategoryTranslations[mainsCategory] }</MenuItem>
					<MenuItem value={secondsCategory}>{ dishesCategoryTranslations[secondsCategory] }</MenuItem>
					<MenuItem value={dessertsCategory}>{ dishesCategoryTranslations[dessertsCategory] }</MenuItem>
					<MenuItem value={drinksCategory}>{ dishesCategoryTranslations[drinksCategory] }</MenuItem>
					<MenuItem value={winesCategory}>{ dishesCategoryTranslations[winesCategory] }</MenuItem>
				</Select>
			</FormControl>
			<div className="col-12 text-left width-fit-content d-block mb-4">
				<Button
					className="text-left m-auto"
					variant="contained"
					color="primary"
				>
					<Link to={'/dishes/new'} className="nav-link">Crear Plato</Link>
				</Button>
			</div>
			{_.isObject(dishesByCategory) &&
				<Table columns={columns} rows={dishesByCategory[categoryDishesSelected]} />
			}
            <Notification propsNotification={propsNotification} setPropsNotification={setPropsNotification} />
        </div>
    )
}

export default DishListPage
