// @ts-nocheck
import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

const getName = (item) => {
	if (item?.name?.es)	return item?.name?.es
	if (item?.name?.en)	return item?.name?.en
	if (item?.name?.ca)	return item?.name?.ca
}

const MenuListItemDraggable = ({ item, index }): JSX.Element => {
  return (
    	<Draggable key={item._id} draggableId={item._id} index={index} style={(_isDragging, draggableStyle) => ({ ...draggableStyle, position: 'static' })}>
      	{(provided) => (
			<div
				className="menu-item-draggable-container"
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
			>
			<Box>
				<Card variant="outlined">
					<CardContent>
						<p className="menu-item-draggable">
							<span>{index+1} </span> 
							{getName(item)}
						</p>
					</CardContent>
				</Card>
    		</Box>
        </div>
      	)}
    	</Draggable>
	)
}

export default MenuListItemDraggable